import Vue from 'vue'
import BlockUI from 'vue-blockui'

Vue.use(BlockUI)

export default {
    data() {
        return {
            defaultMsg : 'Loading',
            html: '<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>',
        }
    }
}