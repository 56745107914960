<template>	
  <div>
    <BlockUI :message="counter" :html="html" v-if="isDownloading"></BlockUI>
    <router-view>
      
  </router-view>
  </div>
  
</template>

<script>
import Loader from './loader.mixin';
import Vue from 'vue';
export default {
  name: 'App',
  data () {
    return {
      isDownloading: false,
      message : '',
      counter_interval: null,
      elapsed: 0,
      show_elapsed: false
    }
  },
  mixins : [Loader],
  computed: {
    counter() {

      if(this.show_elapsed) {
        let min = 0, sec = 0;
        min = Math.floor(this.elapsed / 60);
        sec = this.elapsed - (min * 60);
        return `Elapsed Time: ${min}m ${sec}s`
      }
      else return this.message;
      
    }
  },
  mounted() {
    
    this.message = this.defaultMsg;

    Vue.prototype.$showLoading = (isLoading, message = null, showElapsed = false) => {
      this.isDownloading = isLoading;
      this.show_elapsed = showElapsed;
      this.message = message ?? this.defaultMsg;  
      if(showElapsed) {
        if(isLoading) {
          this.counter_interval = setInterval(() => {
            this.elapsed++;
            console.log('asdsd')
          }, 1000);
        }
        else {
          this.elapsed = 0;
          clearInterval(this.counter_interval)
        }
      }
      else {
        this.elapsed = 0;
          clearInterval(this.counter_interval)
      }
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';

// .table-responsive {
// 	overflow: visible !important;
// 	// min-height: 225px;
// }
</style>
