import Vue from "vue"
import moment from 'moment';
// Vue.filter("number_format", (value, type = null, precision = 2) => {
//     let symbol = (type == 'currency') ? '₱' : '';
//     let zero = 0;
//     if(!value) {
//         return (type == 'currency') ? symbol+ (zero.toFixed(precision)) : zero;
//     }
//     let decimal = !isNaN(value) ? parseFloat(value).toFixed(precision) : zero.toFixed(precision);
//     return symbol + decimal.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")
// });
Vue.filter("date_format", (value, format = 'MM/DD/YYYY') => {
    if (value) {
        return moment(value).format(format);
    }
    return '';
})
Vue.filter("number_format", (value, type = null, precision = 0) => {

    if (isNaN(value)) return value;

    value = value ?? 0;
    let php = '', zero = 0;

    if (type === 'currency') {
        php = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "php"
        }).format(value);
    }
    else {
        if (!value) return zero.toFixed(precision);
        let decimal = !isNaN(value) ? parseFloat(value).toFixed(precision) : zero.toFixed(precision);
        let num = decimal.split('.');
        let num1 = '', num2 = null;

        num1 = num[0];
        num2 = (num.length > 1) ? num[1] : '';
        let amt = "";
        for (let i = num1.length - 1, j = 1; i >= 0; i--, j++) {
            amt = num1[i] + amt;
            if (j % 3 == 0 && i != 0) amt = "," + amt;
        }
        php = num2 ? `${amt}.${num2}` : amt;

    }
    return php;

});
Vue.filter("name_concat", (last_name, first_name, middle_name) => {
    return last_name + ", " + first_name + " " + middle_name;
});
Vue.filter("truncate", (value, length = 30, ellipsis = true) => {
    if (!value || value.length <= length) {
        return value;
    }
    let myTruncatedString = value.substring(0, length);
    myTruncatedString = (ellipsis) ? myTruncatedString + '...' : myTruncatedString;
    return myTruncatedString;

});
Vue.filter("badgeColor", (due_date, time, threshold_unit) => {
    var date = moment(due_date).unix();
    var today = moment(new Date()).startOf('day').unix();
    var week_before = moment(due_date).subtract(time, threshold_unit).unix();
    // var week_before = moment.unix(value.due_date).subtract(value.time, unit).unix(); 
    let renewal_badge = null;
    // var renewal_text = remaining_time
    if (today < week_before) {
        renewal_badge = 'info';
    }
    else if (today >= week_before && today < date) {
        renewal_badge = 'warning';
    }
    else {
        renewal_badge = 'danger';
    }
    return renewal_badge
});
Vue.filter("badgeText", (due_date) => {
    return 'Due ' + moment(due_date).fromNow(); //remaining_time
});
Vue.filter("getTimeUntilDueDate", (dueDate) => {
    const now = new Date();
    const due = new Date(dueDate);
    const timeDiff = due.getTime() - now.getTime();
    const daysUntilDue = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    const weeksUntilDue = Math.floor(daysUntilDue / 7);
    const remainingDays = daysUntilDue % 7;

    if (weeksUntilDue > 0 && remainingDays > 0) {
        return `There are ${weeksUntilDue} week(s) and ${remainingDays} day(s) until the due date.`;
    } else if (weeksUntilDue > 0 && remainingDays === 0) {
        return `There are ${weeksUntilDue} week(s) until the due date.`;
    } else if (weeksUntilDue === 0 && remainingDays > 0) {
        return `There are ${remainingDays} day(s) until the due date.`;
    } else if (daysUntilDue === 0) {
        return "Today is the due date!";
    } else {
        return "The due date has already passed.";
    }
});
Vue.filter("dueDateStatus", (due_date, time, threshold_unit) => {
    var date = moment(due_date).unix();
    var today = moment(new Date()).startOf('day').unix();
    var week_before = moment(due_date).subtract(time, threshold_unit).unix();
    // var week_before = moment.unix(value.due_date).subtract(value.time, unit).unix(); 
    let status = '';
    // var renewal_text = remaining_time
    if (today < week_before || (today >= week_before && today < date)) {
        status = 'Due Soon';
    }
    else {
        status = 'Overdue';
    }
    return status;
});
// Vue.filter("dueDateStatusColor", (due_date, time, threshold_unit) => {
//     var date = moment(due_date).unix();
//     var today = moment(new Date()).startOf('day').unix();
//     var week_before = moment(due_date).subtract(time, threshold_unit).unix();
//     // var week_before = moment.unix(value.due_date).subtract(value.time, unit).unix(); 
//     let color = '';
//     // var renewal_text = remaining_time
//     if (today < week_before || (today >= week_before && today < date)) {
//         color = 'warning';
//     }
//     else {
//         color = 'danger';
//     }
//     return color;
// });


Vue.filter("dueDateStatusTextTest", (due_date, time, threshold_unit) => {
    var today = new Date();
    var due = new Date(due_date);

    var interval = Vue.filter('interval')(due_date, time, threshold_unit);
    var now_ = Date.now() / 1000;
    var due_date_ = new Date(due_date).getTime() / 1000;
    var reminder_date_ = Vue.filter('thresholdDate')(due_date, time, threshold_unit, 'integer');
    var interval = due.getTime() - new Date(new Date(reminder_date_ * 1000).toISOString().substr(0, 10)).getTime();
    var interval_today = due.getTime() - today.getTime();
    var daysDiff = Math.round(interval / (1000 * 60 * 60 * 24));

    if (now_ < reminder_date_) {
        return "Upcoming";
    }
    else if (now_ > reminder_date_ && now_ < due_date_) {
        return "Due soon";
    }
    else if (interval_today / (1000 * 60 * 60 * 24) == 0) {
        return "Due today";
    }
    else {
        return 'Overdue';
    }

});
Vue.filter("dueDateStatusColor", (due_date, time, threshold_unit) => {
    var today = new Date();
    var due = new Date(due_date);

    var interval = Vue.filter('interval')(due_date, time, threshold_unit);
    var now_ = Date.now() / 1000;
    var due_date_ = new Date(due_date).getTime() / 1000;
    var reminder_date_ = Vue.filter('thresholdDate')(due_date, time, threshold_unit, 'integer');
    var interval = due.getTime() - new Date(new Date(reminder_date_ * 1000).toISOString().substr(0, 10)).getTime();
    var interval_today = due.getTime() - today.getTime();
    var daysDiff = Math.round(interval / (1000 * 60 * 60 * 24));

    if (now_ < reminder_date_) {
        return "info";
    }
    else if (now_ > reminder_date_ && now_ < due_date_) {
        return "warning";
    }
    else if (interval_today / (1000 * 60 * 60 * 24) == 0) {
        return "danger";
    }
    else {
        return 'danger';
    }
});

Vue.filter("incidentStatusBudgeColor", (status) => {
    if(status == 'Open'){
        return 'primary';
    }
    else{
        return 'danger';
    }
});
Vue.filter("interval", (due_date, time, threshold_unit) => {
    var reminderDate = Vue.filter('thresholdDate')(due_date, time, threshold_unit, 'date');
    var due = new Date(due_date);
    var diff = Math.abs(reminderDate - due);
    var diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    return { days: diffDays };
});
Vue.filter("thresholdDate", (due_date, time, threshold_unit, type) => {
    var reminderDate = new Date(due_date);
    switch (threshold_unit) {
        case 'minutes':
            reminderDate.setMinutes(reminderDate.getMinutes() - time);
            break;
        case 'hours':
            reminderDate.setHours(reminderDate.getHours() - time);
            break;
        case 'days':
            reminderDate.setDate(reminderDate.getDate() - time);
            break;
        case 'weeks':
            reminderDate.setDate(reminderDate.getDate() - (time * 7));
            break;
        case 'months':
            reminderDate.setMonth(reminderDate.getMonth() - time);
            break;
        case 'years':
            reminderDate.setFullYear(reminderDate.getFullYear() - time);
            break;
        default:
            return;
    }
    if (type == 'integer') {
        return reminderDate.getTime() / 1000; // integer value of date threshold
    } else if (type == 'date') {
        return reminderDate.toISOString().substr(0, 10); // get the threshold date yyyy-mm-dd
    } else {
        return;
    }
});
Vue.filter('CurrencyFormatWithSign', function(value){
    if(value == '' || !value){
        return '₱ 0.00';
    }
    let val = (value/1).toFixed(2);
    return '₱'+ val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    //return '₱' + parseFloat(value).toFixed(2);
});
Vue.filter('CurrencyFormat', function(value){
    if(value == '' || !value){
        return '0.00';
    }
    let val = (value/1).toFixed(2);
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
});
Vue.filter('computeTotal', function(labor_amount,materials){
        let total = 0;
        let la = labor_amount ? labor_amount:0;
        if(materials.length == 0){
            return 0+parseFloat(labor_amount);
        }
      
        materials.forEach(data => {
            total = total + parseFloat(data.total);
        });
        return total+parseFloat(la);
});
Vue.filter('epochToDate', function(data){
    if(!data || data == null || data == ''){
        return 'N/A';
    }
    var d = new Date(data * 1000);
    var formattedDate = d.toISOString().substr(0, 10);
    return d;
});
Vue.filter('DateToEpoch', function(data){
    return moment(data, "MM/DD/YYYY").startOf('day').valueOf() / 1000;
});

Vue.filter('colorStatus', function(data){
    if(data == 'Upcoming'){
        return 'info';
    }
    else if(data == 'Due soon'){
        return 'warning';
    }
    else if(data == 'Overdue'){
        return 'danger';
    }
    else if(data == 'Due today'){
        return 'secondary';
    }
    else{
        return;
    }
});
Vue.filter('termsCompute', function(term){
    if(term == '' || !term){
        return '---';
    }else{
        let date = new Date();
        const newDate = date.setDate(date.getDate() + term);
        return moment(newDate).format('DD MMM YYYY');
    }
});
Vue.filter('dateFormat', function(date){
    return moment(new Date(date)).subtract(1, "days").format("MM/DD/YYYY");
});
Vue.filter('errorValidate', function(errors, fields){
    let msg = '';
    fields.forEach(field => {
        
        if(errors[field][0] != '' && errors[field][0]){
            return errors[field][0];
        }
    });
    return msg;
});