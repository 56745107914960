import Vue from 'vue';
const methodsPlugin = {
    install(Vue, options) {
      Vue.prototype.isNumber = (evt) => {
            const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.'];
            const keyPressed =  evt.key;
            let value = evt.target.value;
            
            if (!keysAllowed.includes(keyPressed)) {
                evt.preventDefault()
            }
            // allow 1 dot
            if(keyPressed == '.' && value.includes('.')) evt.preventDefault()
      },
      
      Vue.prototype.is_trucking = (evt) => {
        return JSON.parse(window.localStorage.getItem('user_data')) && JSON.parse(window.localStorage.getItem('user_data')).company ? 
        JSON.parse(window.localStorage.getItem('user_data')).company.company_type.toLowerCase().includes('trucking') : false
      },
      Vue.prototype.is_trucking_agency = (evt) => {
        return JSON.parse(window.localStorage.getItem('user_data')) && JSON.parse(window.localStorage.getItem('user_data')).company ? 
        JSON.parse(window.localStorage.getItem('user_data')).company.company_type.toLowerCase().includes('trucking with agency') : false
      },
      Vue.prototype.is_roadwise = (evt) => {
        return JSON.parse(window.localStorage.getItem('user_data')) && JSON.parse(window.localStorage.getItem('user_data')).company ? 
        JSON.parse(window.localStorage.getItem('user_data')).company.company_name.toLowerCase().includes('roadwise') : false
      },

      Vue.prototype.is_marvel = (evt) => {
        return JSON.parse(window.localStorage.getItem('user_data')) && JSON.parse(window.localStorage.getItem('user_data')).company ? 
        JSON.parse(window.localStorage.getItem('user_data')).company.company_name.toLowerCase().includes('marvel') : false
      },

      Vue.prototype.is_monocrete = (evt) => {
        return JSON.parse(window.localStorage.getItem('user_data')) && JSON.parse(window.localStorage.getItem('user_data')).company ? 
        JSON.parse(window.localStorage.getItem('user_data')).company.company_name.toLowerCase().includes('monocrete') : false
      },
      
      

      Vue.prototype.disable_when = (roles = [], other = true) => {
        const is_roadwise = JSON.parse(window.localStorage.getItem('user_data'))?.company?.company_name?.toLowerCase()?.includes('roadwise');

        if(!is_roadwise) return false;

        const user_role = JSON.parse(window.localStorage.getItem('user_data'))?.usertype?.setting_name?.toLowerCase();
        const has_roles = roles.filter(val => {
          return val.toLowerCase() == user_role;
        });
        // console.log(has_roles,'sdsd')
        return has_roles.length > 0 && other;
      }

      // same lang
      Vue.prototype.hide_when = (roles = [], other = true) => {
        const is_roadwise = JSON.parse(window.localStorage.getItem('user_data'))?.company?.company_name?.toLowerCase()?.includes('roadwise');

        if(!is_roadwise) return true;

        const user_role = JSON.parse(window.localStorage.getItem('user_data'))?.usertype?.setting_name?.toLowerCase();
        const has_roles = roles.filter(val => {
          return val.toLowerCase() == user_role;
        });
        return !(has_roles.length > 0 && other);
      }

      Vue.prototype.has_role = (roles = []) => {
        
        const user_role = JSON.parse(window.localStorage.getItem('user_data'))?.usertype?.setting_name?.toLowerCase();
        const has_roles = roles.filter(val => {
          return val.toLowerCase() == user_role;
        });
        return (has_roles.length > 0);
      }
    },
  }
  Vue.use(methodsPlugin)